import React from 'react';
import Layout from './../components/layout';
import Meta from './../components/meta';
import DynamicComponent from '../components/dynamicComponent';
import { useStoryblok } from '../lib/storyblok';

const TemplatePage = ({ pageContext, location }) => {
	let story = pageContext.story;
	story = useStoryblok(story, location);
  
	const components = story.content.body.map(blok => (
		<DynamicComponent key={blok._uid} blok={blok} />
	));

	return (
		<Layout>
			<Meta
				lang="en"
				title={story.content.title}
				description="whats this then"
			/>
			{components}
		</Layout>
	);
};

export default TemplatePage;
